@charset "UTF-8";

/// A list of all HTML button elements.
///
/// @type list
///
/// @access private

$_buttons-list: (
  "button",
  "[type='button']",
  "[type='reset']",
  "[type='submit']",
);
