@charset "UTF-8";

/// Sets the `width` and `height` of the element in one statement.
///
/// @argument {number (with unit) | string} $width
///
/// @argument {number (with unit) | string} $height [$width]
///
/// @example scss
///   .first-element {
///     @include size(2em);
///   }
///
///   // CSS Output
///   .first-element {
///     width: 2em;
///     height: 2em;
///   }
///
/// @example scss
///   .second-element {
///     @include size(auto, 10em);
///   }
///
///   // CSS Output
///   .second-element {
///     width: auto;
///     height: 10em;
///   }
///
/// @require {function} _is-size

@mixin size(
  $width,
  $height: $width
) {
  @if _is-size($height) {
    height: $height;
  } @else {
    @error "`#{$height}` is not a valid length for the `$height` argument " +
           "in the `size` mixin.";
  }

  @if _is-size($width) {
    width: $width;
  } @else {
    @error "`#{$width}` is not a valid length for the `$width` argument " +
           "in the `size` mixin.";
  }
}
