.posts-list-page {
  display: flex;

  .posts-list-column {
    flex-grow: 3;
  }

  .category-list-column {
  }
}

.posts-list {
  margin-top: 1.5rem;
  .post-list-item {
    display: flex;

    &:first-child {
      padding-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

  }
}

.post-date-column {
  padding-right: 0.5rem;
  flex: 0 0 116px;
}

.post-item-details {
  display: block;
  flex: 1;
  margin-bottom: 0.5rem;
}
