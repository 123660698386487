@charset "UTF-8";

/// Checks for a valid CSS color.
///
/// @argument {string} $color
///
/// @return {boolean}
///
/// @access private

@function _is-color($color) {
  @return (type-of($color) == color) or ($color == "currentColor");
}
