.site-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem 0;
  background-color: white;
  border-top: 1px solid #EEE;
}

.footer-links {
  @include grid-container;
  @include site-column;

  color: $text-color;

  a {
    font-weight: 400;
  }

  .navigation-links-list {
    margin: 0;
    @include inline-list;

    > li:first-child {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

