@charset "UTF-8";

/// Provides a concise, one-line method for setting `border-width` on specific
/// edges of a box. Use a `null` value to “skip” edges of the box with standard
/// CSS shorthand.
///
/// @argument {list} $values
///   List of border widths; accepts CSS shorthand.
///
/// @example scss
///   .element {
///     @include border-width(1em null 20px);
///   }
///
///   // CSS Output
///   .element {
///     border-bottom-width: 20px;
///     border-top-width: 1em;
///   }
///
/// @require {mixin} _directional-property

@mixin border-width($values) {
  @include _directional-property(border, width, $values);
}
