.post-full {
  header {
    margin-bottom: 1.5rem;
  }
  .post-full-title {
    line-height: 1.5;
    margin-top: 0.125rem;
  }

  article {
    img {
      max-width: 95%;
    }
  }

  .post-navigation {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .prev, .next {
      width: 40%;
    }
  }
}
