.landing {
  padding: 1rem 0;

  h1 {
    font-size: 1.75rem;
  }

  h3 {
    font-weight: 400;
    font-size: 1.375rem;
    color: lighten($text-color, 10%);
    line-height: 1.7;
    margin-top: 2rem;
  }
}
