hr {
  display: block;
  margin: 2rem auto;
  width: 20rem;
  border-top: 2px solid #EEE;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

