.pagination {
  margin: 1rem 0;
  padding-left: 0;

  > li {
    font-size: 0.95rem;
    list-style-type: none;
    display: inline-block;
    padding: 0 0.25rem;
  }

  > li:first-child {
    padding-left: 0;
  }
}

