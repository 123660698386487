@charset "UTF-8";

/// Provides a concise method for targeting `padding` on specific sides of a
/// box. Use a `null` value to “skip” a side.
///
/// @argument {list} $values
///   List of padding values; accepts CSS shorthand.
///
/// @example scss
///   .element-one {
///     @include padding(null 1rem);
///   }
///
///   // CSS Output
///   .element-one {
///     padding-left: 1rem;
///     padding-right: 1rem;
///   }
///
/// @example scss
///   .element-two {
///     @include padding(10vh null 10px 5%);
///   }
///
///   // CSS Output
///   .element-two {
///     padding-bottom: 10px;
///     padding-left: 5%;
///     padding-top: 10vh;
///   }
///
/// @require {mixin} _directional-property

@mixin padding($values) {
  @include _directional-property(padding, null, $values);
}
