@charset "UTF-8";
/// Creates a series of guide lines using the `background-image` property on a
/// grid container to visualise the columns and gutters of the grid.
///
/// @group features
///
/// @name Grid visual
///
/// @argument {color} $color
///   The color of the guide lines created.
///
/// @argument {map} $grid [$neat-grid]
///   The grid used to determine the guides
///
/// @example scss
///   .element {
///     @include grid-visual;
///   }
///
/// @example css
///   .element {
///     background-image: repeating-linear-gradient( … ) ;
///   }

@mixin grid-visual($color: null, $grid: $neat-grid) {
  @if not $color {
    $color: _retrieve-neat-setting($grid, color);
  }

  $_grid-columns: _retrieve-neat-setting($grid, columns);
  $_grid-gutter: _retrieve-neat-setting($grid, gutter);
  $_grid-visual-column: "#{_neat-column-width($grid, 1)} + #{$_grid-gutter}";
  $_grid-visual:
    transparent,
    transparent $_grid-gutter,
    $color $_grid-gutter,
    $color calc(#{$_grid-visual-column}),
  ;

  background-image: repeating-linear-gradient(to right, $_grid-visual);
}
