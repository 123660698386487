@charset "UTF-8";

/// Provides a concise, one-line method for setting `border-style` on specific
/// edges of a box. Use a `null` value to “skip” edges of the box with standard
/// CSS shorthand.
///
/// @argument {list} $values
///   List of border styles; accepts CSS shorthand.
///
/// @example scss
///   .element {
///     @include border-style(dashed null solid);
///   }
///
///   // CSS Output
///   .element {
///     border-bottom-style: solid;
///     border-top-style: dashed;
///   }
///
/// @require {mixin} _directional-property

@mixin border-style($values) {
  @include _directional-property(border, style, $values);
}
