@mixin inline-list() {
  padding-left: 0;

  > li {
    padding-left: 0;
    list-style-type: none;

    @media only screen and (min-width: 480px){
      display: inline-block;
      padding: 0 0.5rem;
    }
  }
}

@mixin site-column() {
  padding: 0 2rem;
  max-width: 768px;
  margin: 0 auto 0 0;
  display: block;
}
