@charset "UTF-8";

/// Transforms shorthand that can range from 1-to-4 values to be 4 values.
///
/// @argument {list} $shorthand
///
/// @example scss
///   .element {
///     margin: _unpack-shorthand(1em 2em);
///   }
///
///   // CSS Output
///   .element {
///     margin: 1em 2em 1em 2em;
///   }
///
/// @access private

@function _unpack-shorthand($shorthand) {
  @if length($shorthand) == 1 {
    @return nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1);
  } @else if length($shorthand) == 2 {
    @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 1) nth($shorthand, 2);
  } @else if length($shorthand) == 3 {
    @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 3) nth($shorthand, 2);
  } @else {
    @return $shorthand;
  }
}
