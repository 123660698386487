@charset "UTF-8";

////
/// @type list
///
/// @require {function} _assign-inputs
///
/// @require {variable} $_buttons-list
////

/// A list of all HTML button elements. Please note that you must interpolate
/// the variable (`#{}`) to use it as a selector.
///
/// @example scss
///   #{$all-buttons} {
///     background-color: #f00;
///   }
///
///   // CSS Output
///   button,
///   [type='button'],
///   [type='reset'],
///   [type='submit'] {
///     background-color: #f00;
///   }

$all-buttons: _assign-inputs($_buttons-list);

/// A list of all HTML button elements with the `:active` pseudo-class applied.
/// Please note that you must interpolate the variable (`#{}`) to use it as a
/// selector.
///
/// @example scss
///   #{$all-buttons-active} {
///     background-color: #00f;
///   }
///
///   // CSS Output
///   button:active,
///   [type='button']:active,
///   [type='reset']:active,
///   [type='submit']:active {
///     background-color: #00f;
///   }

$all-buttons-active: _assign-inputs($_buttons-list, active);

/// A list of all HTML button elements with the `:focus` pseudo-class applied.
/// Please note that you must interpolate the variable (`#{}`) to use it as a
/// selector.
///
/// @example scss
///   #{$all-buttons-focus} {
///     background-color: #0f0;
///   }
///
///   // CSS Output
///   button:focus,
///   [type='button']:focus,
///   [type='reset']:focus,
///   [type='submit']:focus {
///     background-color: #0f0;
///   }

$all-buttons-focus: _assign-inputs($_buttons-list, focus);

/// A list of all HTML button elements with the `:hover` pseudo-class applied.
/// Please note that you must interpolate the variable (`#{}`) to use it as a
/// selector.
///
/// @example scss
///   #{$all-buttons-hover} {
///     background-color: #0f0;
///   }
///
///   // CSS Output
///   button:hover,
///   [type='button']:hover,
///   [type='reset']:hover,
///   [type='submit']:hover {
///     background-color: #0f0;
///   }

$all-buttons-hover: _assign-inputs($_buttons-list, hover);
