a {
  text-decoration: none;
  color: $brand-primary;
  transition: color 200ms ease-in-out;

  &:hover {
    color: darken($brand-primary, 10%);
  }
}

p {
  display: block;
  font-size: 1rem;
  margin-bottom: 1rem;
}

strong {
  font-weight: 700;
}

ul, ol {
  display: block;
  margin-bottom: 1rem;
  padding-left: 1.15rem;
  list-style-type: none;
}

li {
  font-size: 1rem;
}

ul {
  li {
    list-style-type: disc;
  }

  &.unstyled {
    padding-left: 0;
    li {
      list-style-type: none;
    }
  }
}

ol {
  li {
    list-style-type: decimal;
  }
}

code {
  padding: 0.25rem;
  color: #333;
  font-size: 0.95rem;
  background-color: #f5f5f5;
  border-radius: 4px;
  font-family: $monospaced-font-family;
}

pre {
  display: block;
  padding: 1.25rem 1.5rem;
  margin: 0 0 1rem;
  font-size: 0.95rem;
  line-height: 1.5;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: $solarized-bg-color;
  border-radius: 4px;
  font-family: $monospaced-font-family;
}

pre {
  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 0;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $base-font-family;
  line-height: 1.5;
  margin: 1.25rem 0 0.5rem;
  color: $heading-color;
}

h1, h2, h3{
  font-weight: 700;
}

h4, h5, h6 {
  font-weight: 500;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.375rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1.125rem;
}

h5, h6  {
  font-weight: 700;
  text-transform: uppercase;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.85rem;
}

blockquote {
  border-left: 2px solid $brand-red;
  font-size: 1rem;
  padding-left: 1.5rem;
  color: lighten($text-color, 25%);
  margin: 2rem 0;

  p:last-child {
    margin-bottom: 0;
  }
}

em, i {
  font-style: italic;
}

.text-muted {
  color: lighten($text-color, 35%);
}

dl {
  dt {
    font-weight: 700;
    font-size: 1rem;
    color: $heading-color;
  }

  dd {
    padding-left: 1.5rem;
    margin-bottom: 1rem;
    font-size: 1rem;

    p:first-child {
      margin-top: 1rem;
    }
  }
}
