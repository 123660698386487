@charset "UTF-8";

/// Provides a concise, one-line method for setting `border-radius` on both the
/// top-left and top-right of a box.
///
/// @argument {number (with unit)} $radii
///
/// @example scss
///   .element {
///     @include border-top-radius(4px);
///   }
///
///   // CSS Output
///   .element {
///     border-top-left-radius: 4px;
///     border-top-right-radius: 4px;
///   }

@mixin border-top-radius($radii) {
  border-top-left-radius: $radii;
  border-top-right-radius: $radii;
}

/// Provides a concise, one-line method for setting `border-radius` on both the
/// top-right and bottom-right of a box.
///
/// @argument {number (with unit)} $radii
///
/// @example scss
///   .element {
///     @include border-right-radius(3px);
///   }
///
///   // CSS Output
///   .element {
///     border-bottom-right-radius: 3px;
///     border-top-right-radius: 3px;
///   }

@mixin border-right-radius($radii) {
  border-bottom-right-radius: $radii;
  border-top-right-radius: $radii;
}

/// Provides a concise, one-line method for setting `border-radius` on both the
/// bottom-left and bottom-right of a box.
///
/// @argument {number (with unit)} $radii
///
/// @example scss
///   .element {
///     @include border-bottom-radius(2px);
///   }
///
///   // CSS Output
///   .element {
///     border-bottom-left-radius: 2px;
///     border-bottom-right-radius: 2px;
///   }

@mixin border-bottom-radius($radii) {
  border-bottom-left-radius: $radii;
  border-bottom-right-radius: $radii;
}

/// Provides a concise, one-line method for setting `border-radius` on both the
/// top-left and bottom-left of a box.
///
/// @argument {number (with unit)} $radii
///
/// @example scss
///   .element {
///     @include border-left-radius(1px);
///   }
///
///   // CSS Output
///   .element {
///     border-bottom-left-radius: 1px;
///     border-top-left-radius: 1px;
///   }

@mixin border-left-radius($radii) {
  border-bottom-left-radius: $radii;
  border-top-left-radius: $radii;
}
