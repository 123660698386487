$base-font-family: 'Helvetica Neue', Helvetica, 'Segoe UI', Arial, sans-serif;
$base-serif-family: 'Palatino', 'Garamond', 'Georgia', 'Times New Roman', serif;
$monospaced-font-family: 'Monaco', 'Consolas', 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace;
$base-font-size: 14px;
$base-line-height: 1.9;

$solarized-bg-color: #002B36;
$text-color: #444;
$heading-color: #111;
$brand-primary: #0074D9;
$brand-red: #D84A38;

$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;

$base-duration: 150ms;
$base-timing: ease;

