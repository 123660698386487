@charset "UTF-8";

/// Performs gamma correction on a single color channel.
///
/// Note that the calculation is approximate if a `pow()` is not available.
///
/// @argument {number (0-1)} $channel
///
/// @return {number (0-1)}
///
/// @access private

@function _gamma($channel) {
  @if $channel < 0.03928 {
    @return $channel / 12.92;
  } @else {
    $c: ($channel + 0.055) / 1.055;
    @if function-exists("pow") {
      @return pow($c, 2.4);
    } @else {
      @return 0.56 * $c * $c * $c + 0.44 * $c * $c;
    }
  }
}
