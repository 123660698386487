.site-header {
  display: block;
  padding: 1rem 0;
  margin-bottom: 2rem;
  position: relative;
  border-bottom: 1px solid #EEE;
}

.site-title {
  @include grid-container;
  color: $text-color;
  @include site-column;

  a {
    font-weight: 400;
  }

  .navigation-links-list {
    margin: 0;
    @include inline-list;

    > li:first-child {
      padding-left: 0;
      padding-right: 0;
    }
  }
} 
