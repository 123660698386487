@charset "UTF-8";

/// A list of all _text-based_ HTML inputs.
///
/// @type list
///
/// @access private

$_text-inputs-list: (
  "[type='color']",
  "[type='date']",
  "[type='datetime']",
  "[type='datetime-local']",
  "[type='email']",
  "[type='month']",
  "[type='number']",
  "[type='password']",
  "[type='search']",
  "[type='tel']",
  "[type='text']",
  "[type='time']",
  "[type='url']",
  "[type='week']",
  "input:not([type])",
  "textarea",
);
